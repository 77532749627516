import axios from 'axios'

export default{
  state: {
    // localities: [
    //   {name: 'Петропавловск-Камчатский', id:1, position:{top: '1180', left: '960'}, titlePosition: 'top', alwaysVisible: true, operators:['rtk', 'sktv', 'mts'] },
    //   {name: 'Елизово',  id:2, position:{top: '1145', left: '935'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk', 'nova']  },
    //   {name: 'Вилючинск', id:3, position:{top: '1205', left: '922'}, titlePosition: 'bottom', alwaysVisible: true, operators:['rtk', 'sktv'] },
    //   {name: 'Мильково', id:4, position:{top: '850', left: '1090'}, titlePosition: 'left', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Усть-Большерецк', id:5, position:{top: '1100', left: '635'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Усть-Камчатск', id:6, position:{top: '715', left: '1670'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Апача', id:7, position:{top: '1128', left: '760'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Березняки', id:8, position:{top: '1119', left: '920'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk'] },
    //   {name: 'Вулканный', id:9, position:{top: '1164', left: '926'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk', 'nova'] },
    //   {name: 'Дальний', id:10, position:{top: '1102', left: '844'}, titlePosition: 'top', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Зелёный', id:11, position:{top: '1117', left: '911'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk'] },
    //   {name: 'Кавалерское', id:12, position:{top: '1093', left: '687'}, titlePosition: 'left', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Ключи', id:13, position:{top: '635', left: '1480'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Козыревск', id:14, position:{top: '638', left: '1345'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Коряки', id:15, position:{top: '1113', left: '923'}, titlePosition: 'top', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Красный', id:16, position:{top: '1155', left: '950'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk', 'nova'] },
    //   {name: 'Крутобереговый', id:17, position:{top: '1165', left: '949'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk'] },
    //   {name: 'Лесной', id:18, position:{top: '1104', left: '911'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk'] },
    //   {name: 'Нагорный', id:19, position:{top: '1161', left: '951'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk', 'nova'] },
    //   {name: 'Начики', id:20, position:{top: '1138', left: '868'}, titlePosition: 'right', alwaysVisible: false, operators:['rtk'] },
    //   {name: 'Николаевка', id:21, position:{top: '1174', left: '918'}, titlePosition: 'right', alwaysVisible: false, operators:['rtk', 'nova'] },
    //   {name: 'Новый', id:22, position:{top: '1158', left: '934'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk', 'nova'] },
    //   {name: 'Озерновский', id:23, position:{top: '1402', left: '529'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Октябрьский', id:24, position:{top: '1130', left: '627'}, titlePosition: 'bottom', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Паратунка', id:25, position:{top: '1186', left: '903'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk', 'nova'] },
    //   {name: 'Пионерский', id:26, position:{top: '1169', left: '957'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk', 'sktv'] },
    //   {name: 'Раздольный', id:27, position:{top: '1125', left: '936'}, titlePosition: 'left', alwaysVisible: true, operators:['rtk', 'nova'] },
    //   {name: 'Светлый', id:28, position:{top: '1177', left: '960'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk'] },
    //   {name: 'Сокоч', id:29, position:{top: '1123', left: '854'}, titlePosition: 'right', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Сосновка', id:30, position:{top: '1170', left: '919'}, titlePosition: 'right', alwaysVisible: false, operators:['rtk', 'nova'] },
    //   {name: 'Термальный', id:31, position:{top: '1190', left: '903'}, titlePosition: 'right', alwaysVisible: false, operators:['rtk', 'nova'] },
    //   {name: 'Шаромы', id:32, position:{top: '889', left: '1022'}, titlePosition: 'left', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Эссо', id:33, position:{top: '623', left: '1203'}, titlePosition: 'top', alwaysVisible: true, operators:['rtk'] },
    //   {name: 'Южные Коряки', id:34, position:{top: '1116', left: '915'}, titlePosition: 'top', alwaysVisible: false, operators:['rtk'] },  ],
    //
    localitiesApi:[],
    operatorsApi:[],
  },
  mutations:{
    SET_LOCALITIES_TO_STATE(state, localities){
      state.localitiesApi = localities
    },
    SET_OPERATORS_TO_STATE(state, operators){
      state.operatorsApi = operators
    },
  },
  actions:{
    async GET_LOCALITIES_FROM_API ({commit}) {

      commit('SET_LOADING', true)
      const apiUrl = this.state.config.apiUrl

      await axios.get(apiUrl+'/api/map&k='+this.state.config.apiKey)
        .then(function (response) {
          // handle success
          // console.log(response.data.localities);
          commit('SET_LOCALITIES_TO_STATE', response.data.localities)
          commit('SET_OPERATORS_TO_STATE', response.data.operators)

          // commit('SET_LOADING', false)
        })
        .catch(function (error) {
          // handle error
          // commit('SET_LOADING', false)
          console.log(error);
        }) ;

    },
  },

  getters: {
    getLocalities(state){
      return state.localitiesApi
    },
    getOperators(state){
      return state.operatorsApi
    },

  }
}
