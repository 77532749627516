<template lang="html">
  <div class="localities_list-wrapper">

    <div class="input-wrapper">
        <div class="input-container ">
          <input class = "locality-search-input" placeholder = 'Населённый пункт' type="text" name="" value="" @input = 'updatelocalitiesArray'>
          <font-awesome-icon @click = "clearSearchArray" class = 'locality-search-icon' :icon="['fas', 'times']" />
        </div>
      </div>
    <div class="localities_list">


      <ul>

          <li v-for = "locality, i in localitiesArray"
          :key = "i"
          @click = "$emit('localityInfo', locality.position.left, locality.position.top, locality.id)"
          class = 'locality-list-item'
          :class = "{'active-locality-list': activeLocality == locality.id}"
          >{{locality.name}}</li>



      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props:[
    'activeLocality',
    'showLocalitiesList'
  ],
  data() {
    return{
      localitiesArray: [],

    }

  },
  methods:{
    updatelocalitiesArray(e){

        if(e.target.value == ""){
          this.localitiesArray = this.localities
        }else{
          this.localitiesArray = []
          this.localities.forEach(item => {

            if(item.name.toLowerCase().includes(e.target.value.toLowerCase())){

              this.localitiesArray.push(item)
            }


          })
        }

    },

    clearSearchArray(){
    document.querySelector('.locality-search-input').value = ""
      this.localitiesArray = this.localities
    },
    showList(){
      if(window.screen.width < 577){
        if(this.showLocalitiesList == true){
          document.querySelector('.localities_list-wrapper').style = "max-height: inherit"
        }else{
          document.querySelector('.localities_list-wrapper').style = "max-height: 0"
        }

      }
    }
  },
  computed:{
    localities(){

      return this.$store.getters.getLocalities
    },
  },
  mounted: function(){

  },
  watch:{
    localities(){
      this.localitiesArray = this.localities
    },
    showLocalitiesList(){
      this.showList()
    }
  }


}
</script>

<style lang="css" scoped>
.localities_list-wrapper{
  overflow-y: scroll;
  max-height: inherit;
  display: flex;

  flex-direction: column;
  max-width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 #eee;
  background-color: #fbfbfb;
  padding-left: 20px;
  flex: 1;
  transition: all .5s;
}

.localities_list-wrapper::-webkit-scrollbar{
  width: 2px;
}

.localities_list-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.localities_list-wrapper::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.locality-list-item{
  cursor: pointer;

  list-style-type: none;
  font-size: 1.1rem;
  padding-bottom: 3px;
  padding-top: 3px;
  padding-left: 20px;
  padding-right: 20px;
}

.active-locality-list{
  background-color: #353535;
  color: #fff;
}

.locality-list-item:hover{
  background-color: #aeadad;
  color: #fff;

}

.localities_list{
  display:flex;

  padding-top: 20px;
}

.localities_list ul{
  width: 100%;
}

.input-wrapper{
  display: flex;
  box-shadow: 1px 3px 9px #00000047;
  justify-content: center;
  max-width:100%;
  position: sticky;
  top: 0;

  padding: 6px 0 12px;
  background-color: #e6e6e6;
}

.input-container {
  display: flex;
  min-width: 90%;
  background-color: #fbfbfb;
  border: 1px solid #8e8d8d;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  border-radius: 4px;
  padding: 2px;
  max-height: 45px;


}

.locality-search-input{
  width: 100%;
 padding: 10px;
 outline: none;
 border:none;
 font-size: 1.2rem;
 background-color: #fbfbfb;
 line-height: normal;

}

.locality-search-icon{
  font-size: 1.5rem;
  padding-right: 7px;
  color: #8e8d8d;
  cursor: pointer;
  background-color: #fbfbfb;
}


input:focus::-webkit-input-placeholder
{
    color: transparent;
}

input:focus::-moz-placeholder{
    color: transparent!important;
}



@media screen and (max-width: 576px) {
      .localities_list-wrapper{
        padding: 0;
        max-height: 0;
      }

      .input-wrapper{
        padding: 5px 0 7px;
        border-radius: 4px;
      }

      .input-container {

        min-width: 90%;




        border-radius: 4px;
        padding: 2px;
        max-height: 39px;


      }

      .locality-search-input{
        width: 100%;
       padding: 6px;

       font-size: .95rem;
       background-color: #fbfbfb;

      }

      .locality-list-item {

          font-size: 1rem;
          padding-bottom: 2px;
          padding-top: 2px;
          padding-left: 15px;
          padding-right: 15px;
}

}

</style>
