<template lang="html">
  <div class="">
    <div id="zoom"

    >

        <button class = "zoom-btn-plus"

       @click = "$emit('mapZoom', 'plus')"> <font-awesome-icon :icon="['fas', 'plus']" /></button>


        <button class = "zoom-btn-minus"
         @click = "$emit('mapZoom', 'minus')">  <font-awesome-icon :icon="['fas', 'minus']" /></button>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
   methods:{
     zoomMap(direction){

         this.$store.commit('updateMapScale', {val: direction})

     }
   }
}
</script>

<style lang="css" scoped>


#zoom{
  position: absolute;
  z-index: 5;
  top: 40%;
  right: 25px;
  outline: none;

}

#zoom button{
  outline: none;
  color: #fff;
  height: 45px;
  width: 45px;
  border: 2px solid #4f4f4f;
  border-radius: 50%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: #959595;
  cursor: pointer;
  opacity: 0.5;
  font-size: 1.5rem;

}

#zoom button:hover{
  opacity: 0.8;
}

button:active, button:focus {
    outline: none;
    background: #999;
    padding: 0;
    cursor: none;
}

#zoom button:first-child{
  margin-bottom: 3px;
}


@media screen and (max-width: 450px) {
      #zoom button{
        cursor: none;
      }
}

</style>
