<template lang="html">
    <div id="map_wrapper">

      <div id="map_section"

      >

      <div class="map-channel-logo">
        <img src="@/assets/img/logo_small.png" alt="41 регион">
      </div>
      <div class="map-google-logo">
        <a href = "https://www.google.com/maps/@54.7440803,160.513208,823590m/data=!3m1!1e3" target = "_blank">
            <img src="@/assets/img/googlemaps_logo.png" alt="Google maps">
        </a>



      </div>

          <div id="map_img_wrapper"

          >

          <div class="map_scale_block" :style = "map_transform_scale_style">

            <div class="map_scroll_block"

            @mousedown = "mouseDown"
            @touchstart = "touchStart"
            @mouseup = "mouseUp"
            @touchend = "mouseUp"
            @mouseleave = "mouseUp"
            @wheel = 'mouseWheel'

            :style = "'transform: translate('+this.position.left*(-1)+'px,' +this.position.top*(-1)+'px)'">

            <div id="map_img" >
              <!-- <img   :src = "require('@/assets/img/'+mapImgFile.main)" alt=""> -->
              <img
              class="map_img_item"
              v-for = "i, index in 12"
              :data-map-img-src = "require('@/assets/img/map/map-image_'+i+'.jpg')"
              :key = "index"
              :style = "mapItemStyle" alt="">


            </div>

            <div class="black-layer"
              @mousedown = "modalClose"
              @touchstart = "modalClose"
              @touchend = "mouseUp"
              v-if = "modalVisible"
            >   </div>
            <div v-for = "locality, i in localitiesArray" :key = "i"  >
              <div

                v-if = "locality.alwaysVisible" :style = "{top: locality.position.top + 'px', left: locality.position.left + 'px',}"
                class ="locality-title"
                :class = "{'active-locality-map': activeLocality == locality.id}"
                >
                <span><font-awesome-icon  :icon="['fas', 'circle']" /></span>

                <p
                :class = "{'locality-title-left': locality.titlePosition == 'left', 'locality-title-right': locality.titlePosition == 'right', 'locality-title-top': locality.titlePosition == 'top', 'locality-title-bottom': locality.titlePosition == 'bottom',}"
                :style = "{'fontSize': mapLocalityFontSize+'rem'}"
                @mouseover = 'LocalityTitleHover'
                @mouseleave  = 'LocalityTitleLeave'
                :data-locality-id = 'locality.id'
                @click =   "localityInfo(locality.position.left, locality.position.top, locality.id)"
                @touchstart =   "localityInfo(locality.position.left, locality.position.top, locality.id)"
                @touchend = "mouseUp"
                >{{locality.name}}</p>
                </div>
            </div>

              </div>

              </div>

              <zoom
                @mapZoom = "zoomMap"
                v-if = "!modalVisible"
              ></zoom>
            <transition name="modal-view">
              <modal-operators
                v-if = "modalVisible"
                @closeModal = "modalClose"
                :localityId = "localityId"
              ></modal-operators>
              </transition>
          </div>

          </div>

          <localities-list
            :activeLocality = activeLocality
            :showLocalitiesList = showLocalitiesList
            v-on:localityInfo = "localityInfo"
          ></localities-list>
          <div class="show-localities_list_btn">
            <p

              @click = "mobileShowLocalities"
              type="button" name="button">{{showButtonText}}</p>
          </div>

    </div>
</template>

<script>
export default {
  data(){
    return {

      map: null,
      mapImg: null,
      mapScaleBlock: null,
      mapScrollBlock: null,
      position:{
        top: null,
        left: null,
        x: 0,
        y: 0
      },

      startLeftPosition: null,
      startTopPosition: null,
      localityId: 1,
      modalVisible : false,
      activeLocality: null,
      localitiesArray: [],
      touchEvents: [],
      startTouchDistance: null,
      startMapScale: null,
      mapLocalityFontSize: 0.92,
      tochesDistArray: [],
      baseFontSize: 0.92,
      imgLoaded: 0,
      mapItemRow: 3,
      mapItemCol: 4,
      mapItemWidth: null,
      showLocalitiesList: false,
      showButtonText: "Показать список населённых пунктов"
    }
  },
  methods:{
    mouseDown(e){
      e.preventDefault()
      this.map.style.cursor = 'grabbing'
      this.map.style.userSelect = 'none'
      //console.log(this.position.left)
      this.startLeftPosition = this.position.left
      this.startTopPosition = this.position.top

      this.position.x = e.clientX
      this.position.y = e.clientY


      //
        //console.log( this.position.left )


      // console.log(this.touchEvents)



      this.mapScrollBlock.style.transition = "transform 0s"
      this.map.addEventListener('mousemove', this.mouseMove)


      // координаты на карте
      console.log(Math.abs(this.mapImg.getBoundingClientRect().y) + e.clientY)
      console.log(Math.abs(this.mapImg.getBoundingClientRect().x)  + e.clientX)



    },

    touchStart(e){
      e.preventDefault()

      this.startLeftPosition = this.position.left
      this.startTopPosition = this.position.top
      this.startMapScale = this.mapScale
      // console.log(this.startMapScale)

      // if(e.changedTouches && e.changedTouches[0].target.id == 'map_img'){
      //   this.touchEvents.push(e.changedTouches[0])
      // }
      if(e.touches.length > 1 ){


        this.startTouchDistance = this.getTouchDistanse(e.touches)

        this.map.addEventListener('touchmove', this.touchZoom)
        this.map.removeEventListener('touchmove', this.mouseMove)
      }else if(e.touches.length === 1){

        //  console.log(this.touchEvents)
          this.position.x = e.changedTouches[0].clientX
          this.position.y = e.changedTouches[0].clientY
          // console.log('touch')
          // console.log(e.changedTouches[0])
          // console.log(this.position.x )


          if(isNaN(this.position.left) || isNaN(this.position.top)){
            //console.log(1)
            let mapTransformValue = this.mapScrollBlock.style.transform
            let mapTransformCoordsStart = mapTransformValue.indexOf('(')

            let coordsArray = mapTransformValue.slice(mapTransformCoordsStart+1, mapTransformValue.length-1).split(',')

            this.startLeftPosition = this.position.left = Math.abs(coordsArray[0].replace('px', ''))
            this.startTopPosition = this.position.top = Math.abs(coordsArray[1].replace('px', ''))


          }








          this.map.addEventListener('touchmove', this.mouseMove)


}




    },

    mouseUp(){

        this.map.style.cursor = 'grab'
        this.map.style.removeProperty('user-select')
        this.mapScaleBlock.style.transformOrigin = "center"
        this.mapScrollBlock.style.transition = "transform 0.5s"
      this.map.removeEventListener('mousemove', this.mouseMove)
      this.map.removeEventListener('touchmove', this.touchZoom)
      this.touchEvents = []
      this.tochesDistArray = []

    },
    mouseMove(e){
        this.mapScrollBlock.style.transition = "transform 0s"

       let mousePositionX = e.clientX ;
      let mousePositionY = e.clientY;


      if(mousePositionX === undefined && mousePositionY === undefined){
        mousePositionX = e.changedTouches[0].clientX
        mousePositionY = e.changedTouches[0].clientY

      }


      let dx = (mousePositionX - this.position.x )/this.mapScale;
      let dy = (mousePositionY - this.position.y)/this.mapScale;

      //console.log( this.position.x)
     //console.log( dx )


      if((this.map.getBoundingClientRect().y -  this.mapScrollBlock.getBoundingClientRect().y) - 20 >= 0 && this.mapImg.getBoundingClientRect().bottom - this.map.getBoundingClientRect().bottom > 0 ){
            // console.log(this.position.top )

              this.position.top = this.startTopPosition - dy



      }else if((this.mapImg.getBoundingClientRect().bottom - this.map.getBoundingClientRect().bottom) - 20 < 0){
        this.map.removeEventListener('mousemove', this.mouseMove)
        this.position.top = this.position.top - 30

      }else{
        this.map.removeEventListener('mousemove', this.mouseMove)
        this.position.top = this.position.top + 30

      }
      // console.log(this.map.getBoundingClientRect().x -  this.mapScrollBlock.getBoundingClientRect().x)
      if((this.map.getBoundingClientRect().x -  this.mapScrollBlock.getBoundingClientRect().x) - 20 >= 0  && this.mapImg.getBoundingClientRect().right - this.map.getBoundingClientRect().right > 0 ){
        // console.log(this.position.left)

          this.position.left = this.startLeftPosition - dx


      }else if ((this.mapImg.getBoundingClientRect().right - this.map.getBoundingClientRect().right) - 20 < 0){
        this.map.removeEventListener('mousemove', this.mouseMove)
        this.position.left = this.position.left - 35

      }else{
        this.map.removeEventListener('mousemove', this.mouseMove)
        this.position.left = this.position.left + 30

      }


    },
    mouseWheel(e){

      if(e.deltaY < 0){
        this.zoomMap('plus')
      }else if(e.deltaY > 0){
        this.zoomMap('minus')
      }
    },

    touchZoom(e){

      this.mapScrollBlock.style.transition = "transform 0s"
      // let originCoordX = (e.touches[0].clientX + e.touches[1].clientX)/2
      // let originCoordY = (e.touches[0].clientY + e.touches[1].clientY)/2
      //   this.mapScaleBlock.style.transformOrigin = originCoordX+'px '+originCoordY+'px'

       this.zoomMap(null , this.startMapScale*Math.pow(this.getTouchDistanse(e.touches)/this.startTouchDistance, 2))



    },

    zoomMapCorrect(){



          if( (this.map.getBoundingClientRect().x -  this.mapScrollBlock.getBoundingClientRect().x) - 50 <= 0){
              //console.log(123)
            this.position.left = this.position.left + this.mapImg.getBoundingClientRect().left*this.mapScale
          }
          // if( (this.mapImg.getBoundingClientRect().bottom - this.map.getBoundingClientRect().bottom) - 20 <= 0){
          //
          //   this.position.top = this.position.top + this.mapImg.getBoundingClientRect().top*this.mapScale
          // }






    },

    async localityInfo(x, y, id){
     await document.querySelectorAll(".locality-title p").forEach(item => {
        item.removeAttribute("style")

      })


      this.zoomMap(null, 1)
      this.localitiesArray = JSON.parse(JSON.stringify(this.localities))
      this.modalVisible = true
      this.localityId = id
      this.position.left = x - this.map.getBoundingClientRect().width/2
      this.position.top = y - this.map.getBoundingClientRect().height/2
      this.localityClick = true
      this.activeLocality = id
      this.mapLocalityFontSize = this.baseFontSize
      this.localitiesArray.forEach(item => {
        if(item.id == id){

          item.alwaysVisible = true

        }
      })

    },

    LocalityTitleHover(e){
      e.target.parentNode.classList.add('locality-title-hover')
    },
    LocalityTitleLeave(e){
      e.target.parentNode.classList.remove('locality-title-hover')
    },

    zoomMap(val, scale = 0){

        this.modalClose()
        this.$store.commit('updateMapScale', {val, scale})
        let scaleValue = Number(this.mapScale).toFixed(1)

        this.mapLocalityFontSize = this.baseFontSize/scaleValue



    },
    modalClose(){

      this.modalVisible = false
      this.activeLocality = null
      this.localitiesArray = JSON.parse(JSON.stringify(this.localities))

    },

    getTouchDistanse(touches){
       return Math.sqrt(Math.pow(touches[0].clientX - touches[1].clientX, 2) + Math.pow(touches[0].clientY - touches[1].clientY, 2))

    },

   loadMapImg(){

    // this.mapImg.setAttribute('src', this.mapImg.getAttribute('data-map-img-src'))

    const mapImageItems = document.querySelectorAll('.map_img_item')
    mapImageItems.forEach(item => {

      item.setAttribute('src', item.getAttribute('data-map-img-src'))
        item.addEventListener('load', () => {
        this.imgLoaded++
          // console.log('img')
      })
    })



    },

    mapLoaded(){
      if(document.querySelector('.preload-map-img') != null){
        document.querySelector('.preload-map-img').remove()
      }
      //
      document.getElementById('map_wrapper').style.opacity = '1'
      this.position.left = this.localities[0].position.left - this.map.getBoundingClientRect().width/2
      this.position.top = this.localities[0].position.top - this.map.getBoundingClientRect().height/2
    },
    mobileShowLocalities(){
      this.showLocalitiesList = !this.showLocalitiesList
      if(window.screen.width < 577){
        if(this.showLocalitiesList == true){
          this.map.style = "max-height: 300px"
          this.showButtonText = "Скрыть список населённых пунктов"
        }else{
          this.map.style = "max-height: 400px"
          this.showButtonText = "Показать список населённых пунктов"

        }

      }
    }

  },
  computed:{
    map_transform_scale_style(){
        return {transform: 'scale('+this.mapScale+')'}
      },

    map_rect(){
      return this.map.getBoundingClientRect()
    },
    localities(){
      return this.$store.getters.getLocalities
    },
    mapScale(){
      return this.$store.getters.getMapScale
    },
    scaleStep(){
      return this.$store.getters.getScaleStep
    },
    scaleStepLimit(){
      return this.$store.getters.getScaleStepLimit
    },
    mapImgFile(){
      return this.$store.getters.getMapImg
    },

    mapItemStyle(){

      return "width:"+this.mapItemWidth+"px"
    }


  },

  watch:{
    mapScale(val, oldVal){
      if(val < oldVal){
        this.zoomMapCorrect()
      }
    },
    imgLoaded(val){

      if(val == document.querySelectorAll('.map_img_item').length){

            this.mapLoaded()

      }
    },
    localities(){
      this.localitiesArray = JSON.parse(JSON.stringify(this.localities))

    }

  },

  async mounted (){
      await  this.$store.dispatch("GET_LOCALITIES_FROM_API")
      this.imgLoaded = 0

      // console.log(this.localitiesArray)
      this.map = document.getElementById('map_img_wrapper')
      this.mapImg = document.getElementById('map_img')
      this.mapScaleBlock = this.map.firstChild
      this.mapScrollBlock = this.mapScaleBlock.firstChild
      this.mapItemWidth = this.mapImg.getBoundingClientRect().width/this.mapItemCol
      this.loadMapImg()






},
updated: function(){

},
created () {
  // this.$store.dispatch("GET_LOCALITIES_FROM_API")


},
}
</script>

<style lang="css" scoped>


    #map_wrapper{
      display: flex;
      max-width: 1140px;
      max-height: 450px;
      margin: 0 auto;
      font-family: sans-serif;
      padding: 5px 0;
      background-color: #fbfbfb;
      opacity: 0;
      transition: opacity 0.5s;
      line-height: 1.2;
    }

    #map_section{

      position: relative;
      display:flex;
        flex: 1.6;


    }
    #map_img_wrapper{
      position: relative;
      max-width: 700px;
      max-height: 450px;

      overflow:hidden;
      cursor: grab;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      transition: all .4s;

    }
    #map_img{
      width: 2800px;
      line-height: 0;
    }



    .map_scale_block{
      transform-origin: center;
       max-width: 100%;
      max-height: inherit;
      transition: transform 0.2s linear;
    }
    .map_scroll_block{

      transition: transform 0.5s linear;
    }

    .locality-title{
      height: 2px;
      width: 2px;
      color: #f0f0f0;
      display: block;
      /* border: 2px solid #fff; */
      border-radius: 100%;
      position: absolute;
       cursor: pointer;
      /* background-color: #fff; */


    }
    .locality-title span{
      top: -4px;
      left: -2px;
      display:block;
      position: absolute;
      /* color: #fc4c02; */
        color: #f0f0f0;
      font-size: 0.4rem;
      /* background-color: #fff; */
      cursor: grab;

    }

      .locality-title p{

          font-size: .92rem;
          white-space: nowrap;
          position: absolute;
          transition: font-size 0.2s linear;

      }
      .locality-title-hover p{
        text-shadow: 1px 7px 2px black;
        padding: 2px 5px;
        /* background-color: #0000004a; */
        color: #fff;
        transition: padding 0.17s linear;

      }
      .locality-title-hover span{



        color: #fc4c02;


      }

    /* .locality-title:hover{
       cursor: pointer;
      color: yellow;
    } */

    .locality-title-left, .locality-title-right, .locality-title-top, .locality-title-bottom{
      display: inline;
      position: relative;
      margin: 0;

    }

    .locality-title-left {
      left: 15px;
      top: -10px;
    }

    .locality-title-right{
      right: 15px;
      top: -10px;
    }
    .locality-title-top{
      bottom: 10px;
      left: -15px;
    }
    .locality-title-bottom{
      top: 10px;
      left: -15px;

    }
    .active-locality-map{
      z-index: 15;

    }
    .active-locality-map p{
      font-size: 1.5rem !important;
      transition: font-size 0.2s linear;

    }

    .black-layer{
      width: 500%;
      height: 100%;
      background-color: black;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.5;
      z-index: 5
}

  .map-channel-logo{
    position: absolute;
    top: 25px;
    left: 32px;
    z-index: 2;
    background-color: #fff;
    padding: 2px 7px;
    opacity: 0.7;
  }
  .map-channel-logo img{
    max-width: 140px;
  }
  .map-google-logo{
    position: absolute;
    bottom: 22px;
    right: 25px;
    z-index: 2;
    background-color: #fff;
    padding: 1px 7px;
    opacity: 0.25;
    border-radius: 3px;
  }
  .map-google-logo img{
    max-width: 60px;

  }

  .modal-view-enter-active, .modal-view-leave-active {
  transition: transform .4s;
}
  .modal-view-enter, .modal-view-leave-to {
  transform: translate(0, 100%);
}

.show-localities_list_btn{
  display: none;
}


@media screen and (max-width: 1400px) {
  #map_wrapper{

    padding: 15px;


  }
}

@media screen and (max-width: 1100px) {
  #map_wrapper{

    padding: 15px;


  }

  #map_img_wrapper{
    max-width: 590px;

  }
}

@media screen and (max-width: 920px) {


  #map_img_wrapper{
    max-width: 520px;

  }
}

@media screen and (max-width: 820px) {
    #map_wrapper{
      flex-direction: column;
      padding: 15px;
      max-height: 750px;

    }
    #map_img_wrapper{
      max-width: 100%;

    }

    #map{
      margin-bottom: 20px;
    }

    .black-layer{
      width: 1000%;

}

    .map-channel-logo{

      top: 20px;
      left: 22px;

    }

    .map-google-logo{

      bottom: 15px;
      right: 15px;

    }
    .map-channel-logo img{
      max-width: 100px;
    }

    .map-google-logo img{
      max-width: 60px;

    }
}

@media screen and (max-width: 576px) {
    #map_wrapper{
      flex-direction: column;
      padding: 15px;
      max-height: 580px;
    }

    #map_img_wrapper{
      max-height: 440px;

    }

    #map{
      margin-bottom: 10px;
    }

    .black-layer{
      width: 1000%;

}

    .map-channel-logo{

      top: 17px;
      left: 17px;

    }

    .map-google-logo{

      bottom: 15px;
      right: 12px;

    }
    .map-channel-logo img{
      max-width: 100px;
    }

    .map-google-logo img{
      max-width: 55px;

    }
    .active-locality-map p {
        font-size: 1.35rem !important;

    }
    .show-localities_list_btn{
      display: flex;
      justify-content: center;
      margin: 7px 0;
      cursor: pointer;
      color: #217bee;
      background-color: #e3e3e3;
      padding: 8px 5px;

    }
    .show-localities_list_btn p{
      text-align: center;
      text-transform: uppercase;
      font-size: .9rem;
      font-weight: bold;


    }
}


</style>
