import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import store from './store'
import Mapzoom from '@/components/Mapzoom'
import Loclist from '@/components/LocalitiesList'
import Modaloperators from '@/components/Modaloperators'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'




Vue.config.productionTip = false
Vue.use(Vuex)
Vue.component('zoom', Mapzoom)
Vue.component('localities-list', Loclist)
Vue.component('modal-operators', Modaloperators)

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false


new Vue({
  axios,
  store,

  render: h => h(App),
}).$mount('#app')
