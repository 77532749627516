export default{
  state:{
    mapScale: 1,
    scaleStep: 0.1,
    scaleStop: false,
    scaleStepLimit:{
      plus: 5,
      minus: 4
    },
    mapImg: {
      main:'kamchatka_map2.jpg',
      preload: 'kamchatka_map_preload.jpg'
    }
  },
  mutations:{
    updateMapScale(state, payload){


      let scaleValue = Number(state.mapScale).toFixed(1)

      if(payload.val == 'plus' && payload.scale == 0 &&  scaleValue <= 1+state.scaleStep*state.scaleStepLimit.plus){


          state.mapScale += state.scaleStep




      }else if(payload.val == "minus" && payload.scale == 0 && scaleValue > 1-state.scaleStep*state.scaleStepLimit.minus){


          state.mapScale -= state.scaleStep

      }else if(payload.scale != 0){

        if(payload.scale > 1-state.scaleStep*state.scaleStepLimit.minus && payload.scale <= 1+state.scaleStep*state.scaleStepLimit.plus){
          state.mapScale = Number(payload.scale.toFixed(1))
        }

      }
    }
  },
  getters:{
    getMapScale(state){
      return state.mapScale
    },
    getScaleStep(state){
      return state.scaleStep
    },
    getScaleStepLimit(state){
      return state.scaleStepLimit
    },
    getMapImg(state){
      return state.mapImg
    },

  }
}
