import Vue from 'vue'
import Vuex from 'vuex'
import localities from './localities'
import store from './store'
import config from './config'
import shared from './shared'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    config,
    store,
    localities,
    shared
  }
})
