<template lang="html">
  <div class="modal-operators-wrapper">

    <div class="modal-operator-card">
      <div class="modal-operator-card_title">
        <h3>{{localityInfo[0].name}}</h3>
      </div>

      <div class="modal-operator-card_content">
        <transition-group name="cards-view" tag = 'div' class = 'card-animation-wrapper'>
        <div v-for = 'operator, i in localityOperatorInforation' :key = 'i' class="modal-operator-card_item">
          <a :href = "operator.link" target = "_blank">
          <div class="operator-card_item-header">
            <div class="card-header-logo" >
              <img  :src = "require('@/assets/img/'+operator.img)" alt="">
            </div>




          </div>
          <div class="operator-card_item-body">
            <div class="card-channel-info">
              <p class = "channel-info_text">Канал</p>
              <p class = "channel-info_num">{{operator.channel}}</p>
            </div>
          </div>

          </a>
        </div>
  </transition-group>
      </div>

    </div>
    <button type="button" name="button"
    @click = "$emit('closeModal')"
    ><font-awesome-icon :icon="['fas', 'times']" /></button>
  </div>
</template>

<script>
export default {
  props:{
    localityId:{
      type: Number,
      default: 1,
    }
  },
  data(){
    return{
      // operators: [
      //   {name: 'rtk', title: 'Ростелеком', link: 'https://kamchatka.rt.ru/', channel: 23, img: 'RGB_RT_logo-horizontal_main_ru.png'},
      //   {name: 'sktv', title: 'СКТВ', link: 'https://www.kamtv.ru/', channel: 26, img: 'sktv_logo.png'},
      //   {name: 'nova', title: 'Студия Нова', link: 'http://elizovo.ru/', channel: 21, img: 'nova-logo.png'},
      //   {name: 'mts', title: 'МТС', link: 'https://kamchatka.mts.ru/', channel: 23, img: 'MTS_TV_Corp_Rus_Red.png'},
      // ],
      localityOperatorInforation:[]
    }
  },
  methods:{
      createInfoArray(){
        this.localityOperatorInforation = []
        this.localityInfo[0].operators.forEach(item => {
          this.operators.forEach(operator => {
            if(operator.name == item){
              this.localityOperatorInforation.push(operator)
            }
          })
        })




      }
  },
  computed:{
      localityInfo(){
        return this.$store.getters.getLocalities.filter(item => item.id == this.localityId)
      },
      operators(){
        return this.$store.getters.getOperators
      },
  },
  watch:{
    localityId(){

      this.createInfoArray()
    }
  },
  mounted: function(){
    this.createInfoArray()
  }
}
</script>

<style lang="css" scoped>

a{
  text-decoration: none;
  outline: none;
}

.modal-operators-wrapper{
  width: 100%;
  height: 152px;
  position: absolute;
  bottom: 0;

}

.modal-operator-card{
  position: relative;
  z-index:3;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.54);
}
.modal-operator-card_title{
  text-align:center;
  padding: 7px;
  background-color:#fc4c0278;

}
.modal-operator-card_title h3{
  margin: 0;
  color: #eee;
  font-size: 1.2rem;
  font-weight: bold;
}

.modal-operator-card_content{
  display: flex;
  justify-content: center;
}
.modal-operator-card_item{
  /* border: 1px solid #fff; */
  width: 120px;
  background-color: rgba(51, 51, 51, 0.54);
  margin: 3px;
  transition: all 0.5s;
}



.modal-operators-wrapper button{
  position: absolute;
  top: 3px;
  right: 15px;
  cursor: pointer;
  z-index: 40;
  outline: none;
  color: #eee;
  height: 35px;
  width: 35px;
  border: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.9;
  font-size: 1.4rem;
}

button:active, button:focus {
    padding: 0;
    color: #403d95

}

.operator-card_item-header{
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #aeaeae;
  overflow: hidden;
  display: flex;
  padding: 3px;
  justify-content: center;

}

.card-header-logo{
  max-width: 110px;
  height: 100%;



}

.card-header-logo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-channel-info{
  display: flex;
  flex-direction: column;
  text-align: center;
    font-weight: bold;
}

.channel-info_text{
  font-size: .85rem;
  color: #cecece;
  padding: 0;
  margin: 0;
}

.channel-info_num{
  font-size: 2.2rem;
  color: #d9d9d9;
  padding: 0;
  margin: 0;
}

.operator-card_item-body{
  margin-top: 4px;
  padding-bottom: 3px;
}

.card-animation-wrapper{
  display:flex;
  width: 100%;
  justify-content: center;

}




.cards-view-enter-active {
transition: transform .5s;
}
 .cards-view-leave-active{
   position: absolute;
   transition: transform .4s;
 }
.cards-view-enter {
transform: translate(0, 110%);
}
.cards-view-leave-to {
transform: translate(400%, 0);
}


@media screen and (max-width: 576px) {
      .modal-operators-wrapper button{
        display: none;
      }

      .modal-operators-wrapper{

        height: 125px;

      }
      .modal-operator-card_title {

        padding: 5px;


    }
      .modal-operator-card_title h3 {



        font-size: 1.2rem;
    }
    .modal-operator-card_item{
        width: 102px;
        margin: 3px 4px;
    }
    .operator-card_item-header {

        height: 30px;

        padding: 2px;

    }
    .channel-info_text {
          font-size: .75rem;
          margin-bottom: -2px;

      }
      .channel-info_num {
          font-size: 1.9rem;

      }

}

</style>
